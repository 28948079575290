'use strict'

import { app, router } from '../main'

export default {
  handle (data, resolve, reject) {
    if (data.error === true || data.status === 'ERR') {
      if (data.msg === 'not_authorized' && router.currentRoute?._rawValue?.meta?.requiresAuth === true) {
        window.confirm('Your session has expired')
        return window.location.reload()
      }
      reject(data)
    } else {
      resolve(data)
    }
  },
  get (path) {
    return new Promise((resolve, reject) => {
      fetch(`${app.config.globalProperties.$apibaseurl}v1.0/filmateade/${path}`, {
        method: 'GET',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        credentials: 'include'
      }).then(response => response.json()).then((data) => {
        this.handle(data, resolve, reject)
      }).catch((err) => {
        window.alert('Error: ' + err)
        reject(err)
      })
    })
  },
  post (path, data) {
    return new Promise((resolve, reject) => {
      fetch(`${app.config.globalProperties.$apibaseurl}v1.0/filmateade/${path}`, {
        method: 'POST',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify(data)
      }).then(response => response.json()).then((data) => {
        this.handle(data, resolve, reject)
      }).catch((err) => {
        window.alert('Error: ' + err)
        reject(err)
      })
    })
  },
  put (path, data) {
    return new Promise((resolve, reject) => {
      fetch(`${app.config.globalProperties.$apibaseurl}v1.0/filmateade/${path}`, {
        method: 'PUT',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify(data)
      }).then(response => response.json()).then((data) => {
        this.handle(data, resolve, reject)
      }).catch((err) => {
        window.alert('Error: ' + err)
        reject(err)
      })
    })
  },
  delete (path) {
    return new Promise((resolve, reject) => {
      fetch(`${app.config.globalProperties.$apibaseurl}v1.0/filmateade/${path}`, {
        method: 'DELETE',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        credentials: 'include'
      }).then(response => response.json()).then((data) => {
        this.handle(data, resolve, reject)
      }).catch((err) => {
        window.alert('Error: ' + err)
        reject(err)
      })
    })
  }

}
