import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import EditIcon from 'icons/Pencil'
import DeleteIcon from 'icons/Delete'
import CheckCircle from 'icons/CheckCircle'
import SendIcon from 'icons/Send'

const app = createApp(App)

app.component('edit-icon', EditIcon)
  .component('check-circle', CheckCircle)
  .component('delete-icon', DeleteIcon)
  .component('send-icon', SendIcon)

app.use(store).use(router).mount('#app')
app.config.globalProperties.$apibaseurl = process.env.NODE_ENV !== 'production' ? 'http://localhost:3338/' : 'https://services-api.clickndrive.ee/'

export { app, router }
